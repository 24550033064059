var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"mb-4"},[_vm._v(" Database Copy ")])])],1),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"xs":"12","sm":"6","md":"4"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Databases")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":['prod', 'staging', 'qa', 'nngroup'],"label":"fromEnv*","required":""},model:{value:(_vm.fromEnv),callback:function ($$v) {_vm.fromEnv=$$v},expression:"fromEnv"}}),_c('v-select',{attrs:{"items":[
                      'adobe',
                      'aon',
                      'anthem',  
                      'aah', 
                      'bain',                          
                      'baptisthealth', 
                      'beam',
                      'campsite', 
                      'cbre',
                      'chr',
                      'cigna',                      
                      'conoco', 
                      'cookchildrens',
                      'genentech',                      
                      'medsol',                         
                      'monigle',
                      'nemours',                      
                      'nngroup',                              
                      'peloton',
                      'quest',                       
                      'optum',                                           
                      'salesforce',                      
                      'schneider-electric',
                      'sch',
                      'shc',                    
                      'som',                      
                      'spgi',
                      'troweprice',                    
                      'uchealth',
                      'unitedhealthcare',
                      'unc-health',                      
                      'unc-real-estate',                                         
                      'vertex',
					            'westmonroe',
                      'wtw'
                    ],"label":"fromClient*","disabled":!_vm.fromEnv.length,"required":""},model:{value:(_vm.fromClient),callback:function ($$v) {_vm.fromClient=$$v},expression:"fromClient"}}),_c('v-select',{attrs:{"items":['staging', 'qa'],"label":"toEnv*","required":""},model:{value:(_vm.toEnv),callback:function ($$v) {_vm.toEnv=$$v},expression:"toEnv"}}),_c('v-select',{attrs:{"items":[
                      'adobe',
                      'aon',
                      'anthem',
                      'aah', 
                      'bain',                        
                      'baptisthealth',   
                      'beam',
                      'campsite', 
                      'cbre',
                      'chr',
                      'cigna',   
                      'cookchildrens',                   
                      'conoco',
                      'genentech',                      
                      'medsol',                         
                      'monigle',
                      'nemours',                      
                      'nngroup',                                                                                                                                                                                                                            
                      'peloton',
                      'quest',                      
                      'optum',                                           
                      'salesforce',                      
                      'schneider-electric',
                      'sch',
                      'shc',                    
                      'som',                      
                      'spgi',
                      'troweprice',                     
                      'uchealth',
                      'unc-health',                      
                      'unc-real-estate',                     
                      'unitedhealthcare',                     
                      'vertex',
                      'westmonroe',
                      'wtw'
                    ],"label":"toClient*","disabled":!_vm.toEnv.length,"required":""},model:{value:(_vm.toClient),callback:function ($$v) {_vm.toClient=$$v},expression:"toClient"}})],1)],1)],1),_c('small',[_vm._v("*indicates required field")]),_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":this.handleDownload}},[_vm._v(_vm._s(_vm.getDownloadedFileName > "" ? _vm.getDownloadedFileName : ''))])])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"id":"submit-deploy","color":"primary","disabled":!_vm.submitEnabled,"large":""},on:{"click":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.handleExport.apply(null, arguments)}}},[_vm._v(" Copy ")])],1)],1)],1)],1),_c('div',{staticClass:"text-center"},[_c('v-snackbar',{attrs:{"vertical":_vm.vertical},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"green","text":"","multi-line":_vm.multiLine},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.msgText)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }