<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1 class="mb-4">
          Database Copy B2
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        xs="12"
        sm="6"
        md="4"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Databases</span>
            <!--<p>User: {{ currentUser.JWT_TOKEN }}</p>-->
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-select
                    v-model="fromEnv"
                    :items="['prod','staging','qa']"
                    label="fromEnv*"
                    required
                  />
                  <v-select
                    v-model="fromClient"
                    :items="[
                      'aig',
                      'allstate',
                      'amica',
                      'anthem',
                      'deloitte',
                      'ballad',
                      'bcbsa',
                      'bnymellon2',
                      'cbre2',
                      'cgi',
                      'chevron',
                      'chi',
                      'chor',
                      'christus',
                      'clearchoice',
                      'dignity',
                      'florida',
                      'gartner',
                      'iff2',
                      'inova',
                      'jointtradeportal',
                      'lcmc',
                      'manpower',
                      'medstarhealth',
                      'mercyhealth',
                      'moniglecampsite',
                      'msk',
                      'musc',
                      'northwell',
                      'nrel',
                      'petrocanada',
                      'pncsignage',
                      'rbc',
                      'storyspace',
                      'sutter',
                      'synchrony',
                      'tdameritrade',
                      'technip',
                      'troweprice',
                      'vapremier',
                      'vcu',
                      'vcuhealth',
                      'vitalent',
                      'wtw',
                      'aah',
                      'yalenewhaven'
                    ]"
                    label="fromClient*"
                    :disabled="!fromEnv.length"
                    required
                  />
                  <v-select
                    v-model="toEnv"
                    :items="['staging', 'qa']"
                    label="toEnv*"
                    required
                  />
                  <v-select
                    v-model="toClient"
                    :items="[
                      'aig',
                      'allstate',
                      'amica',
                      'anthem',
                      'deloitte',
                      'ballad',
                      'bcbsa',
                      'bnymellon2',
                      'cbre2',
                      'cgi',
                      'chevron',
                      'chi',
                      'chor',
                      'christus',
                      'clearchoice',
                      'dignity',
                      'florida',
                      'gartner',
                      'iff2',
                      'inova',
                      'jointtradeportal',
                      'lcmc',
                      'manpower',
                      'medstarhealth',
                      'mercyhealth',
                      'moniglecampsite',
                      'msk',
                      'musc',
                      'northwell',
                      'nrel',
                      'petrocanada',
                      'pncsignage',
                      'rbc',
                      'storyspace',
                      'sutter',
                      'synchrony',
                      'tdameritrade',
                      'technip',
                      'troweprice',
                      'vapremier',
                      'vcu',
                      'vcuhealth',
                      'vitalent',
                      'wtw',
                      'aah',
                      'yalenewhaven'
                    ]"
                    label="toClient*"
                    :disabled="!toEnv.length"
                    required
                  />                                    
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
              <div>
             <a href="#" @click="this.handleDownload">{{ getDownloadedFileName > "" ? getDownloadedFileName : '' }}</a>
             </div>

          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              id="submit-deploy"
              color="primary"
              :disabled="!submitEnabled"
              large
              @click.exact="handleExport"
            >
              Copy
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <div class="text-center">
      <v-snackbar
        v-model="snackbar"
        :vertical="vertical"
      >
        {{ msgText }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="green"
            text
            v-bind="attrs"
            @click="snackbar = false"
            :multi-line="multiLine"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>

  </v-container>
</template>
<script>
import { databaseCopyB2,databaseDownload } from '../services'

export default {
  name: 'deployments',
  data: () => ({
    downloadedFilePath: '',
    downloadedFileName: '',
    deploymentError: false,
    deploymentLoading: false,
    dialog: false,
    errorPullRequests: false,
    fromApplication: '',
    fromClient: '',
    toClient: '',
    formPullRequest: '',
    fromEnv: '',
    toEnv: '',
    loadingPullRequests: false,
    openPullRequests: null,
    snackbar: false,
    msgText: 'Build started. To check status login to CircleCI for more information',
    vertical: true,
  }),
  computed: {
    qaEngineer () {
      return 'qa1'; //this.$store.state.User.data
    },
    formattedPullRequests () {
      return this.openPullRequests
        ? this.openPullRequests.map(pr => pr.head.ref)
        : null
    },
    submitEnabled () {
      return (
        Boolean(this.fromClient.length) &&
        Boolean(this.fromEnv.length) &&
        Boolean(this.toEnv.length) &&
        Boolean(this.toClient.length)
      )
    },
    currentUser () {
      return this.$store.getters['User/userData']
    },
    getDownloadedFileName () {
      return this.downloadedFileName;
    },
    getDownloadedFilePath () {
      return this.downloadedFilePath;
    }
  },
  watch: {
    formApplication: {
      handler: function (value) {
        this.loadPullRequests(value)
      }
    }
  },
  methods: {
    async initNewDeploy () {
      this.dialog = true
    },
    async loadPullRequests (app) {
      this.dialog = true
      this.loadingPullRequests = true

      try {
        this.openPullRequests = await getPullRequests(app)
      } catch {
        this.errorPullRequests = true
      } finally {
        this.loadingPullRequests = false
      }
    },
    async handleDownload () {
       try {
        await databaseDownload({
          filePath: this.getDownloadedFilePath,
          fileName: this.getDownloadedFileName
        }).then(result => {
          //this.downloadedFilePath = result[0].url;
          //this.downloadedFileName = result[0].name
          //this.snackbar = true;
        
        });
      } catch (error) {
        this.deploymentError = true
      

      } finally {
        this.deploymentLoading = false
      }
    },
    async handleExport () {
      this.dialog = false
      this.deploymentLoading = true
      this.downloadedFileName = 'Loading.....'
  
      try {
        await databaseCopyB2({
          fromclient: this.fromClient,
          fromenv: this.fromEnv,
          toenv: this.toEnv,
          toclient: this.toClient,
          authToken: this.$store.getters['User/userData'].JWT_TOKEN,
        }).then(result => {
          this.downloadedFilePath = result[0].url;
          this.downloadedFileName = result[0].name
          //this.snackbar = true;
        });
      } catch (error) {
        this.deploymentError = true
      

      } finally {
        this.deploymentLoading = false
      }
    }
  }
}
</script>
